import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { BackgroundImg } from "./functions/background_img"

import Header from "./header"
import Footer from "./footer"

const Layout_Mov = ({
  location,
  title,
  pageKeywords,
  description,
  isIndex = false,
  bgImg,
  bgVideo,
  children,
}) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allFile(filter: { relativeDirectory: { eq: "background" } }) {
        nodes {
          base
          childImageSharp {
            gatsbyImageData(
              width: 2000
              quality: 50
              webpOptions: { quality: 70 }
            )
          }
        }
      }
    }
  `)

  let bgDevelop = "sm:bg-green-200 md:bg-yellow-200 lg:bg-red-200 xl:bg-blue-200 2xl:bg-purple-200"
  bgDevelop = ""

  let bgStyle = {}

  return (
    <div className={bgDevelop + " relative flex flex-col h-screen"} style={bgStyle}>
      {bgVideo && (
        <video autoPlay loop muted className="absolute inset-0 w-full h-full object-cover z-0">
          <source src={`/${bgVideo}`} type="video/mp4" />
        </video>
      )}
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
  
      <main className="relative flex-1 overflow-y-auto px-0 z-10">
        <div className="lg:px-6 xl:px-20 xl:py-5">{children}</div>
      </main>
  
      <div className="relative bg-white text-center z-10">
        <Footer />
      </div>
    </div>
  )
}




Layout_Mov.propTypes = {
  location: PropTypes.object,
  title: PropTypes.string,
  pageKeywords: PropTypes.string,
  description: PropTypes.string,
  isIndex: PropTypes.bool,
  bgImg: PropTypes.string,
  bgVideo: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Layout_Mov