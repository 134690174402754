import React from "react"
import Seo from "../components/seo"
import Layout_Mov from "../components/layout_mov"

export default function Contact() {
  return (
    <Layout_Mov isIndex={true} bgVideo="natale.mp4" title="Contact Us">

        <Seo title="Contact Us" />

        <div className="flex items-center justify-center min-h-screen pb-60">

            <div className="max-w-xl w-full mx-auto bg-white shadow-md rounded-md overflow-hidden my-8">
                <div className="text-center p-4">
                    <h1 className="text-4xl font-bold">Contact Us</h1>
                    <p className="text-2xl text-gray-700">The Platinum Services Dmc Italy and Events</p>
                </div>
                <div className="pl-12 text-justify text-gray-500 pb-4">
                    <p className="text-gray-600">Operations office</p>
                    <p>Via degli Apuli 54 00185 Roma - Italy</p>
                    <p>Email address: <a className="text-gray-500 hover:text-gray-400" href="mailto:info@theplatinumservices.it">info@theplatinumservices.it</a></p>
                    <p>Tel 06 4460449 Mob Whatsapp : +393339470120</p>
                    <p className="text-sm">Affiliate to : Evintra, Micebook, Connections</p>
                </div>
            </div>

        </div>

    </Layout_Mov>
  )
}
