import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  // faCoffee,
  // faAddressBook,
  // faAirFreshener,
  // faAmbulance,
  // faAtom,
  // faBus,
  // faCoins,
  // faDice,
} from '@fortawesome/free-solid-svg-icons' // <i class="fas fa-bars"></i> diventa faBars
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import NavLink from "./navlink"

import { config } from '@fortawesome/fontawesome-svg-core'
config.autoAddCss = false // Disable the auto CSS insertion


export default function Navbar({ fixed }) {
  const [navbarOpen, setNavbarOpen] = React.useState(false)

  return (
      <>
      <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 bg-white mb-3 shadow-lg pb-1">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between xl:w-auto xl:static xl:block xl:justify-start">
          
           <Link to="/" title="Home page">
                <StaticImage 
                  src="../images/icon.png"
                  width={90}
                  alt="Logo The Platinum Services"
                  className="align-middle mr-4 object-contain w-16"
                />

              <div className="inline-block mt-2 font-chivo font-thin align-middle">
                <div className="text-3xl md:text-4xl xl:text-4xl">The Platinum Services
                </div>
                <div className="ml-0 md:ml-0 text-lg md:text-2xl xl:text-xl">Dmc Italy & Luxury Events</div>
              </div>
            </Link>

            <button
              className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block xl:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <FontAwesomeIcon icon={faBars} className="text-gray-400" /> 
            </button>
          </div>
          <div
            className={
              "xl:flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col xl:flex-row list-none xl:ml-auto">
              {getLinks()}
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}

function getLinks() {
  let objReturn = []

  let iCount = 0
  let links = [
    { name: "about us", path: "/about" },
    { name: "services", path: "/services" },
    { name: "destinations", path: "/destinations" },
    { name: "projects", path: "/projects" },
    { name: "signature experiences", path: "/signature-experiences" },
    { name: "news", path: "/news" },
    { name: "contact", path: "/contact" }
  ]
  

  links.forEach(itmLink => {
    objReturn.push(
      <li key={`navR_${iCount.toString()}`} className="nav-item">
        <NavLink name={itmLink.name} path={itmLink.path} css="text-md px-1 py-2 flex items-center uppercase leading-snug hover:opacity-75" />
      </li>
    )
    
    iCount ++
  })

  return objReturn
}


/*


    <>
      <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 bg-white mb-3 shadow-lg pb-1">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">

           <Link to="/" title="Home page">
                <StaticImage 
                  src="../images/icon.png"
                  width={90}
                  alt="Logo The Platinum Services"
                  className="align-middle mr-4 object-contain w-16"
                />

              <div className="inline-block mt-2 font-chivo font-thin align-middle">
                <div className="text-3xl md:text-4xl xl:text-4xl">The Platinum Services
                </div>
                <div className="ml-0 md:ml-0 text-lg md:text-2xl xl:text-xl">Meetings - Incentives - Conferences - Events</div>
              </div>
            </Link>

            <button
              className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <FontAwesomeIcon icon={faBars} className="text-gray-400" /> 
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              {getLinks()}
            </ul>
          </div>
        </div>
      </nav>
    </>






    <>
      <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 bg-white mb-3 shadow-lg pb-1">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className={"w-full relative flex justify-between " + breakPoint + ":w-auto " + breakPoint + ":static " + breakPoint + ":block " + breakPoint + ":justify-start"}>
            
            <Link to="/" title="Home page">
                <StaticImage 
                  src="../images/icon.png"
                  width={90}
                  alt="Logo The Platinum Services"
                  className="align-middle mr-4 object-contain w-16"
                />

              <div className="inline-block mt-2 font-chivo font-thin align-middle">
                <div className="text-3xl md:text-4xl xl:text-4xl">The Platinum Services
                </div>
                <div className="ml-0 md:ml-0 text-lg md:text-2xl xl:text-xl">Meetings - Incentives - Conferences - Events</div>
              </div>
            </Link>

            <button
              className={"text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block " + breakPoint + ":hidden outline-none focus:outline-none"}
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <FontAwesomeIcon icon={faBars} className="text-gray-400" /> 
            </button>
          </div>
          <div
            className={
              breakPoint + ":flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className={"flex flex-col " + breakPoint + ":flex-row list-none " + breakPoint + ":ml-auto"}>
              {getLinks()}
            </ul>
          </div>
        </div>
      </nav>
    </>












*/